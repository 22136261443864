import React from 'react';

import axios from 'axios';

export default class Body extends React.Component {
    state = {
        login: '',
        password: '',
    };

    onFieldChange(field, event)
    {
        this.setState({
            [field]: event.currentTarget.value,
        });
    }

    async onFormSubmit(event)
    {
        event.preventDefault();

        if(this.state.login !== '' && this.state.password !== '')
        {
            const {data} = await axios.post('/api/auth/login', {
                login: this.state.login,
                password: this.state.password,
            });

            const {success} = data;

            if(success)
            {
                window.location.replace('/service/new');
            }
            else
            {
                window.alert('Złe dane logowania');
            }
        }
    }

    render() {
        return (
            <div className={'login-box'}>
                <div className={'login-box-body'}>
                    <form onSubmit={this.onFormSubmit.bind(this)}>
                        <div className={'form-group'}>
                            <input
                                value={this.state.login}
                                onChange={this.onFieldChange.bind(this, 'login')}
                                name={'login'}
                                className={'form-control'}
                                placeholder={'Login'}
                            />
                        </div>
                        <div className={'form-group'}>
                            <input
                                value={this.state.password}
                                onChange={this.onFieldChange.bind(this, 'password')}
                                name={'password'}
                                type={'password'}
                                className={'form-control'}
                                placeholder={'Hasło'}
                            />
                        </div>
                        <div className={'form-group'}>
                            <button className={'btn btn-success btn-block'}>
                                Zaloguj
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
}